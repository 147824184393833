@layer modules, ui, base;
@layer base {
  .GuestFormSection_title__6FB1J {
  margin-bottom: var(--sk-space-24);
}

.GuestFormSection_note__a7t_x {
  margin-bottom: var(--sk-space-24);
}

.GuestFormSection_buttons__Ku3c1 {
  display: flex;
  gap: var(--sk-space-8);
  flex-wrap: wrap;
  margin-top: var(--sk-space-32);
}

.GuestFormSection_button__7wW6l {
  width: 100%;
}

.GuestFormSection_dataTextTitle__JsH8k {
  margin-top: var(--sk-space-24);
}

@media (min-width: 30rem) {
  .GuestFormSection_button__7wW6l {
    width: auto;
  }
}

}
@layer base {
  .CardPageLink_main__oovmq {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  align-items: center;
}

.CardPageLink_content__JeZR1 {
  flex: 1;
  margin: 0 var(--spacer-200) 0 0;
}

.CardPageLink_wrapperImage__iqjxT {
  padding-right: var(--spacer-200);
}

.CardPageLink_link__Oi5uJ {
  color: var(--color-black);
  text-decoration: none;
}

.CardPageLink_link__Oi5uJ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CardPageLink_link__Oi5uJ:hover::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: calc(var(--spacer-150) * -1);
  left: calc(var(--spacer-150) * -1);
  width: calc(100% + var(--spacer-300));
  height: calc(100% + var(--spacer-300));
  border-radius: var(--radius-200);
  background-color: var(--color-grey--050);
}

:not(.CardPageLink_wrapper__PvhDD) + .CardPageLink_wrapper__PvhDD .CardPageLink_subtitle___S3oI {
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.CardPageLink_image__VRRad {
  display: block;
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: var(--radius-200);
}

@media (max-width: 47.999rem) {
  .CardPageLink_shortDescription__4iIi5 {
    display: none;
  }
}

@media (min-width: 48rem) {
  :not(.CardPageLink_wrapper__PvhDD) + .CardPageLink_wrapper__PvhDD .CardPageLink_subtitle___S3oI {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .CardPageLink_image__VRRad {
    width: 86px;
    height: 86px;
  }
}

}
@layer base {
  .Network_container__yLIzl a[class*="jds-Pagination__activeButton"] {
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.Network_filters__DFLfT {
  position: relative;
  z-index: var(--z-above-ui-kit-cards); /* to override some z-index inside Cards, below the FilterBar */
}

.Network_grid__TrWY3 {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: var(--spacer-200);
  grid-template-columns: repeat(1, 1fr);
  margin-top: var(--spacer-400);
}

.Network_image__ch_cp {
  border-radius: var(--radius-200);
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 64rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 90rem) {
  .Network_grid__TrWY3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Network_grid__withError__4IZ8a {
  grid-template-columns: none;
}

}
@layer base {
  .Title_title__AcGZQ {
  margin: 0 0 var(--spacer-400);
}

@media (min-width: 48rem) {
  .Title_title__AcGZQ {
    margin-bottom: var(--spacer-700);
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .Title_title--small__tCfUd {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer base {
  /**
 * FilterBar component is used to wrap Filters and Select. As it override some styles on children, It suffers same issue
 * as Select (src/modules/uiKitOverrides/SelectFilter/Select.module.css), and need some !important in nextjs context.
 */

.FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
  margin: 0 0 -1px;
}

.FilterBar_filterBar__SW2X9 [class*="jds-Select__control"] {
  min-height: 48px !important;
}

@media (min-width: 48rem) {
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"] {
    margin: 0 -1px -1px 0;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:first-child [class*="jds-Input"] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:last-child [class*="jds-Input"] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Select__control"],
  .FilterBar_filterBar__SW2X9 [class*="jds-FilterBar__element"]:not(:first-child, :last-child) [class*="jds-Input"] {
    border-radius: 0 !important;
  }
}

}
@layer base {
  .ColGroup_featuresCol__ND0wD {
  width: 23.18%;
}

.ColGroup_packCol--limited__yfGel {
  width: 15.54%;
}

.ColGroup_packCol--light__74tlN,
.ColGroup_packCol--classic__sU67X {
  width: 20.44%;
}

.ColGroup_packCol--performance__qWTab {
  max-width: 20.4%;
}

}
@layer base {
  .IconPackState_main__EEmY6 {
  --jt-IconPackState-size: 1.75rem;

  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: var(--jt-IconPackState-size);
  height: var(--jt-IconPackState-size);
  padding: 0.4rem;
  border-radius: 100%;
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  font-size: 1rem;
}

.IconPackState_main--included__cHWdw {
  background-color: var(--sk-color-green-550);
}

.IconPackState_main--notIncluded__DtOrF {
  background-color: var(--sk-color-grey-200);
}

}
@layer base {
  .FeaturePackCell_featurePackCell__lonIj {
  border: none;
  border-bottom: 1px solid var(--jt-subscriptionTable-border-color);
  background-color: var(--sk-color-white);
}

.FeaturePackCell_text__XivnQ {
  display: flex;
  flex-direction: column;
}

.FeaturePackCell_bold__1492d {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

@media (min-width: 64rem) {
  .FeaturePackCell_featurePackCell__lonIj {
    border: none;
  }
}

}
@layer base {
  .RecruitAdInsert_main__YNrG1 {
  --backgroundImage: unset;

  position: relative;
  margin-bottom: var(--sk-space-80);
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-400);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -200px -200px;
  color: var(--sk-color-white);
  text-shadow: var(--sk-color-purple-500) 0 0 0.625rem;
}

.RecruitAdInsert_content__Fy34I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-32);
}

.RecruitAdInsert_cta__IG_mz {
  flex-shrink: 0;
  text-shadow: none;
}

.RecruitAdInsert_text__HQl8G {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .RecruitAdInsert_main__YNrG1 {
    padding: var(--sk-space-56) var(--sk-space-72);
  }

  .RecruitAdInsert_content__Fy34I {
    flex-direction: row;
    gap: var(--sk-space-56);
  }
}

}
@layer base {
  .Nav_app-Nav__YDPug {
  width: 100%;
  margin: 0;
  padding: 0;
}

.Nav_app-Nav__item__Ns0tT {
  display: inherit;
  align-items: center;
}

.Nav_app-Nav__link__W3ONF {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: var(--sk-space-16) 0;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-size: var(--typo-body-2-font-size);
  font-weight: var(--black);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
  white-space: nowrap;
}

.Nav_app-Nav__link__W3ONF:hover {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__link--active__HuxqT {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__newTag__ZpufN {
  margin-left: var(--sk-space-8);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  .Nav_app-Nav__link__W3ONF {
    margin: 0 var(--sk-space-24);
    border-bottom: thin solid var(--themeButtonTextColor, var(--sk-color-grey-100));
  }

  .Nav_app-Nav__personasList__rsQGs {
    margin-top: var(--sk-space-16);
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem--active__HJSRA {
    display: none;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 var(--sk-space-24);
    padding: var(--sk-space-16) 0;
    border-bottom: thin solid var(--sk-color-grey-100);
    color: var(--sk-color-black);
    font-size: var(--typo-body-2-font-size);
    line-height: var(--typo-body-2-line-height);
    text-decoration: none;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Nav_app-Nav__personas__d2Ecs {
    --layout-size: var(--mj-breakpoint);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: var(--layout-size);
    min-height: 40px;
    margin: auto;
  }

  .Nav_app-Nav__personasList__rsQGs {
    display: flex;
    flex-grow: 1;
    gap: 0 var(--sk-space-24);
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem__HuBH4 {
    display: flex;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    position: relative;
    padding: var(--sk-space-8) 0;
    color: var(--sk-color-black);
    font-size: var(--typo-body-3-font-size);
    line-height: var(--typo-body-3-line-height);
    text-decoration: none;
    text-transform: capitalize;
  }

  .Nav_app-Nav__personasLink--active__rGZ4z {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__personasLink__XjLP_:not(.Nav_app-Nav__personasLink--active__rGZ4z) {
    color: var(--sk-color-white);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover,
  .Nav_app-Nav__personasLink--active__rGZ4z {
    color: var(--color-primary);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover::after,
  .Nav_app-Nav__personasLink--active__rGZ4z::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--color-primary);
  }

  .Nav_app-Nav__personasLinkIcon__7ECJk {
    display: none;
  }

  .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  .Nav_app-Nav__link__W3ONF {
    display: flex;
    align-items: center;
    height: var(--sk-space-64);
    padding: 0 0.75rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-align: center;
  }

  .Nav_app-Nav__link--active__HuxqT {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__link__W3ONF:hover::after,
  .Nav_app-Nav__link--active__HuxqT::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    background-color: var(--themeButtonTextColor, var(--color-primary));
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--offers__u7Yqo {
  order: 1;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--companies__QU_5_ {
  order: 2;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--events__IblND {
  order: 3;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--community__17gx_ {
  order: 4;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobs__BR2xW {
  order: 5;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobRoles__aFmJi {
  order: 5;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--advices__D7eCm {
  order: 6;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--handbook__rAQDn {
  order: 7;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--ressources__aK2kz {
  order: 8;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--appointment__J_xa_ {
  order: 9;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--careerExplorer__q_aL5 {
  order: 10;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--login__OX5tA {
  order: 11;
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
  color: var(--sk-color-black);
  font-weight: var(--font-weight-regular);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT {
  font-weight: var(--font-weight-semibold);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__linkLang__MY7Bx {
  color: var(--sk-color-white);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: column;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
    width: 100%;
    border-bottom-color: var(--sk-color-grey-100);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__item--dashboard__1NSLW {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF:hover::after,
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT::after {
    background-color: var(--sk-color-black);
  }
}

}
@layer base {
  .DropdownCommunity_hdr-DropdownCommunity__X_wja {
  display: inline-block;
  position: relative;
  outline: 0;
  cursor: pointer;
}

.DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
  position: static;
}

.DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
  width: 100%;
}

.DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 80%;
  right: 0;
  box-sizing: border-box;
  min-width: 270px;
  padding: 0 1.875rem 1.875rem;
  transition: visibility 0.5s;
  opacity: 0;
  pointer-events: auto;
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw,
.DropdownCommunity_hdr-DropdownCommunity__content___1isw:hover {
  display: block;
  visibility: visible;
  position: static;
  opacity: 1;
  background-color: #ffffff;
  box-shadow: var(--shadow-default);
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
  position: absolute;
  top: 105%;
  width: 98%;
  margin: 0 1%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.DropdownCommunity_hdr-DropdownCommunity__item__by3SB {
  list-style-type: none;
}

/** Dropdown link content */

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
  display: flex;
  align-items: center;
  color: var(--themeButtonTextColor);
  text-decoration: none;
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j:hover {
  background-color: var(--sk-color-grey-025);
  color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j::after {
  background-color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__linkTextWrapper__d37Kl {
  margin: 0;
}

.DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--themeMainTextColor);
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD {
  display: flex;
  align-items: center;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD svg {
  padding-left: var(--sk-space-4);
}

@media (max-width: 47.999rem) {
  /* --small-and-less-only */
  .DropdownCommunity_hdr-DropdownCommunity--nav__bQosD > svg {
    display: none;
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
    position: relative;
  }

  .DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
    width: inherit;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    display: block;
    min-width: 345px;
    padding: 0;
    cursor: initial;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content--left__6B9lO {
    right: inherit;
    left: 0;
  }

  .DropdownCommunity_DropdownCommunity__list__OS6Fe {
    padding: 8px;
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    position: absolute;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
    position: absolute;
    top: 80%;
    width: inherit;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
    padding: 8px;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--sk-color-black);
}

}
@layer base {
  .Header_main__Zk9sm {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  --color-primary: #4dc942; /* Legacy Green */
  --shadow-default: 0 4px 8px rgb(118 118 120 / 20%);
  position: relative;
  z-index: var(--z-index-website-menu);
  background-color: var(--themeMainColor, var(--sk-color-white));
  box-shadow: var(--shadow-default);
}

.Header_nav__IP0B3 {
  --layout-size: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: var(--layout-size);
}

.Header_link__lueRy {
  color: var(--color-grey--900);
  text-decoration: none;
}

.Header_logo___onO5 {
  display: flex;
  grid-column-start: 1;
  align-items: center;
  height: var(--sk-space-64);
}

.Header_dropdown__b9_DX {
  display: flex;
  grid-column-start: 2;
}

.Header_topNav__T8_X2 {
  grid-column: 1 / span 2;
  grid-row-start: 2;
}

.Header_primaryNav__lM5sM {
  grid-column: 1 / span 2;
  grid-row-start: 3;
}

.Header_primaryNav__hidden__JAZy2 {
  visibility: hidden;
}

.Header_language__wLGkn {
  grid-column: 1 / span 2;
  grid-row-start: 4;
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */

  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open {
    position: fixed;
    z-index: inherit;
    top: 0;
    left: 0;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    background-color: inherit;
  }

  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_topNav__T8_X2,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_primaryNav__lM5sM,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_language__wLGkn,
  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open .Header_account__jNuE2 {
    display: none;
  }

  .Header_dropdown__b9_DX {
    flex-direction: row-reverse;
  }

  .Header_primaryNav__lM5sM {
    padding-top: var(--sk-space-16);
    padding-bottom: var(--sk-space-40);
  }

  .Header_topNav__T8_X2 + .Header_primaryNav__lM5sM {
    display: flex;
    align-items: flex-end;
    padding-top: var(--sk-space-48);
  }

  .Header_dropdownButton__Geqm7 {
    display: flex;
    margin-right: var(--sk-space-12);
  }

  .Header_app-Header__dropdownMenu__Lice9 {
    visibility: hidden;
    position: absolute;
    width: 100%;
  }

  .Header_language__wLGkn {
    padding: var(--sk-space-32) var(--sk-space-24);
  }
}

/* Only for devices with small screen height */

@media (max-height: 699px) and (max-width: 1023px) {
  .Header_language__wLGkn {
    padding-top: var(--sk-space-48);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Header_main__fixed__saFy6 {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }

  .Header_nav__IP0B3 {
    --layout-size: var(--mj-breakpoint);
    box-sizing: border-box;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto auto;
    margin: auto;
    padding: 0 var(--sk-space-24);
  }

  .Header_logo___onO5 {
    grid-column: 1;
    grid-row-start: 2;
  }

  .Header_app-Header__logo__Ikfct {
    height: var(--sk-space-32);
  }

  .Header_dropdown__b9_DX {
    grid-column: 3;
    grid-row-start: 2;
  }

  .Header_topNav__T8_X2 {
    grid-column: 1 / span 4;
    grid-row-start: 1;
  }

  .Header_primaryNav__lM5sM {
    grid-column: 2;
    grid-row-start: 2;
    text-align: right;
  }

  .Header_language__wLGkn {
    display: flex;
    grid-column: 4;
    grid-row-start: 2;
  }

  .Header_dropdownButton__Geqm7 {
    display: none;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .Header_main__Zk9sm {
  --color-primary: var(--sk-color-green-500);
  background-color: var(--sk-color-white);
}

[data-theme="jobteaser-uishift"] .Header_logo___onO5 path {
  fill: var(--sk-color-black);
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }
}

/* we just display the logo on the printed version of the page */

@media print {
  .Header_nav__IP0B3 > :not(.Header_logo___onO5) {
    display: none;
  }

  .Header_logo___onO5 {
    height: var(--sk-space-48);
    margin: auto;
  }
}

}
@layer base {
  .ContactsInfo_contactTitle__slxDC {
  margin-bottom: var(--spacer-200);
  font-size: var(--title4);
  line-height: var(--title4-lh);
}

.ContactsInfo_info__OcUlg + .ContactsInfo_info__OcUlg {
  margin-top: var(--spacer-300);
}

.ContactsInfo_divider__v7ESo {
  margin-top: var(--spacer-400);
}

}
@layer base {
  /* Util space classes */
.Form_marginTop__FJcH_ {
  margin-top: var(--spacer-300);
}

.Form_marginLeft___ZSlW {
  margin-left: var(--spacer-300);
}

.Form_smallMarginLeft__YEgTe {
  margin-left: var(--sk-space-12);
}

/* Block title + buttons */
.Form_heading__OYN5R {
  flex: 1 0 auto;
  align-self: center;
  margin: 0;
}

.Form_headingWrapper__iOGgs,
.Form_sectionTitleWrapper__UgSL5 {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--sk-space-32);
  text-align: left;
  place-content: center space-between;
  gap: var(--sk-space-8);
}

.Form_headingWrapper__iOGgs:not(:first-of-type) {
  margin-top: var(--sk-space-32); /* For Team member title */
}

.Form_longButtons__xEHfp,
.Form_shortButtons__0pluC {
  display: flex;
  flex: 0 1 auto;
  gap: var(--sk-space-16);
  align-items: center;
  justify-content: flex-start;
  text-align: right;
}

/* Page top stepper */
.Form_stepper__rR_Fg {
  --Stepper-FulfilledColor: var(--color-black);
}

.Form_stepDisabled__YnbJj {
  cursor: default;
}

/* Form sections - matching side navigation */
.Form_section__kWswL + .Form_section__kWswL {
  margin-top: var(--sk-space-48);
}

.Form_tips__AascT {
  margin-bottom: var(--sk-space-24);
}

.Form_sectionTitle__lEwQY {
  margin-bottom: var(--sk-space-24);
}

.Form_titleContainer__pg_c3 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacer-150);
}

.Form_fieldContainer__dpGgM {
  margin-bottom: var(--sk-space-32);
}

/* Sub-sections (section content split into groups, in Contacts and Teams */
.Form_sectionContent__cV4Sg {
  padding: var(--sk-space-32);
}

.Form_sectionContent__cV4Sg + .Form_sectionContent__cV4Sg {
  margin-top: var(--sk-space-24);
}

@media (min-width: 768px) {
  .Form_sectionTitle__lEwQY,
  .Form_reviewSectionTitle___6Lpl,
  .Form_heading__OYN5R {
    --sk-typography-title-3-font-size: var(--sk-typography-title-2-font-size);
    --sk-typography-title-3-line-height: var(--sk-typography-title-2-line-height);
  }

  .Form_longButtons__xEHfp {
    margin: 0;
  }

  .Form_shortButtons__0pluC {
    justify-content: flex-end;
  }
}

}
@layer base {
  .Header_headerImageContainer__xwXaf {
  aspect-ratio: 7/2;
}

.Header_headerImage__ksvKL {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-100);
  -o-object-fit: cover;
     object-fit: cover;
}

.Header_welcomeTitle__label__rSSZ0 {
  margin-top: var(--spacer-200);
}

}
@layer base {
  .Quotes_quoteInfo__Ui_B_ + .Quotes_quoteInfo__Ui_B_ {
  margin-top: var(--spacer-300);
}

}
@layer base {
  .RecommendedActions_ctaContainer__Ty4Td + .RecommendedActions_ctaContainer__Ty4Td {
  margin-top: var(--spacer-300);
}

.RecommendedActions_ctaTitle__yS4gF {
  margin-bottom: var(--spacer-200);
}

.RecommendedActions_cta__04RRp + .RecommendedActions_cta__04RRp {
  margin-top: var(--spacer-300);
}

.RecommendedActions_divider__tq3yN {
  margin-top: var(--spacer-400);
}

}
@layer base {
  .Services_service__Cpsw6 + .Services_service__Cpsw6 {
  margin-top: var(--spacer-300);
}

}
@layer base {
  .Teams_grid__rNKSB {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--sk-space-16);
}

.Teams_teamsTitle__KQx2w {
  margin-bottom: var(--spacer-200);
}

.Teams_teamName__pDDXf {
  margin-bottom: var(--spacer-300);
}

.Teams_members__fJnfd {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-24);
}

.Teams_teamMemberSubtitle___GnVT {
  margin-bottom: var(--spacer-200);
}

.Teams_staffImage__UxNfz {
  width: 100%;
  max-width: var(--sk-size-56);
  height: auto;
  border-radius: var(--sk-radius-4);
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1;
}

.Teams_team__FodMb + .Teams_team__FodMb {
  margin-top: var(--spacer-300);
}

.Teams_divider__JCxUR {
  margin-top: var(--spacer-400);
}

@media (min-width: 30rem) {
  .Teams_grid__rNKSB {
    gap: var(--sk-space-24);
  }

  .Teams_members__fJnfd {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 64rem) {
  .Teams_grid__rNKSB {
    grid-template-columns: 2fr 5fr 5fr;
  }

  .Teams_members__fJnfd {
    grid-template-columns: 1fr;
  }
}

}
@layer base {
  .NewLanguage_container__c_93_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer-300);
  padding: var(--spacer-500);
  border: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.NewLanguage_buttonsContainer__oW0BS {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-200);
}

@media (min-width: 48rem) {
  .NewLanguage_container__c_93_ {
    padding: var(--spacer-1000);
  }

  .NewLanguage_heading__agdLl {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }
}

}
@layer base {
  .PagesStatus_carousel__ZQbjL {
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  border: var(--border);
  border-radius: var(--radius-100);
}

.PagesStatus_item__WoPIe {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: var(--spacer-200) var(--spacer-300);
  overflow: hidden;
  border: none;
  background: transparent;
  font-family: var(--fontFamily);
  text-align: inherit;
  cursor: pointer;
}

/* setting borders on the first and last items prevents the hover pseudo-element to overflow the card */
/* we can't set an overflow: hidden on the Carousel because it would hide the Carousel arrows */

.PagesStatus_item__first__XG5X3 {
  border-radius: var(--radius-100) 0 0 var(--radius-100);
}

.PagesStatus_item__last__IQnUC {
  border-radius: 0 var(--radius-100) var(--radius-100) 0;
}

.PagesStatus_item__WoPIe:hover::after,
.PagesStatus_item__active__4ThSu::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
}

.PagesStatus_item__WoPIe:hover::after {
  background: var(--color-grey--200);
}

.PagesStatus_item__active__4ThSu::after,
.PagesStatus_item__active__4ThSu:hover::after {
  background: var(--color-black);
}

.PagesStatus_separator__nWorZ {
  flex-shrink: 0;
  width: 1px;
  margin: var(--spacer-200) 0;
  background-color: var(--color-grey--100);
}

.PagesStatus_date__d_EsK {
  color: var(--color-grey--600);
}

}
@layer base {
  .MenuDropdown_dropdown__iM4YR {
  width: 100%;
}

.MenuDropdown_triggerButton__YJCKf {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-color: transparent;
  background-color: transparent;
  color: black;
  gap: var(--spacer-100);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  text-align: left;
}

.MenuDropdown_triggerButton__selected__MR7xG {
  background-color: var(--color-black);
  color: var(--color-white);
}

.MenuDropdown_triggerButton__YJCKf:hover {
  background-color: var(--color-grey--100);
}

/* reset CSS from Dropdown's Button component */
.MenuDropdown_triggerButton__YJCKf:hover::before {
  background-color: transparent;
}

.MenuDropdown_triggerButton__YJCKf:focus {
  border-color: var(--color-black);
  background-color: transparent;
}

.MenuDropdown_triggerButton__selected__MR7xG:hover {
  background-color: var(--color-grey--800);
}

.MenuDropdown_triggerButton__selected__MR7xG:focus {
  background-color: var(--color-black);
}

.MenuDropdown_triggerButtonInitial__Nenpk {
  width: var(--spacer-300);
  height: var(--spacer-300);
  border-radius: var(--radius-900);
  background-color: var(--color-grey--100);
  color: var(--color-black) !important;
  font-size: 10px;
  text-align: center;
}

.MenuDropdown_triggerButton__YJCKf:not(.MenuDropdown_triggerButton__selected__MR7xG):hover .MenuDropdown_triggerButtonInitial__Nenpk {
  background-color: var(--color-grey--200);
}

.MenuDropdown_triggerButtonText__ICZyM {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuDropdown_triggerButtonArrowWithTag__JsOD8 {
  display: flex;
  align-items: center;
  gap: var(--spacer-25);
}

.MenuDropdown_triggerButtonText___hidden__BxAoZ {
  display: none;
}

.MenuDropdown_dropdownUserMenuItemLanguage__9Sune {
  display: flex;
  justify-content: space-between;
  border-bottom: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownUserMenuItemCurrentLocale__ER_l2 {
  text-transform: uppercase;
}

.MenuDropdown_dropdownConsumptionMenuItem__5FgLt {
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-200);
  pointer-events: none;
}

.MenuDropdown_dropdownConsumptionMenuItemLink__j_YkA {
  border-top: thin solid var(--color-grey--100);
}

.MenuDropdown_dropdownConsumptionMenuItemValue___FOWd {
  font-weight: var(--semiBold);
}

.MenuDropdown_newTag__4GObg {
  margin-left: var(--spacer-200);
}

}
@layer base {
  /* stylelint-disable max-line-length */

/**
 * UI-Kit uses react-select and this one uses the Emotion CSS-in-JS library to style its components.
 * CSS-in-JS libraries are not compatible with our @layer strategy of CSS loading.
 * That's why we have to use !important on nextjs context.
 */

.Select_select__qM0Ez [class*="jds-Select__control"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez [class*="jds-Select__dropdown-indicator"] {
  padding-right: var(--spacer-200) !important;
  padding-left: calc(var(--spacer-150) / 2) !important;
  color: var(--color-black) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option"] {
  padding: var(--spacer-150) var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-GroupedCheckbox"] [class*="jds-Select__option"] {
  padding-left: calc(var(--spacer-200) * 2) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-selected"] {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-selected"] [class*="jds-Option__TickBox"] {
  color: var(--color-white) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-focused"],
.Select_select__qM0Ez [class*="jds-Select__option:hover"],
.Select_select__qM0Ez [class*="jds-Select__option:focus"] {
  background-color: var(--color-grey--025) !important;
}

.Select_select__qM0Ez[class*="jds-Select--hasError"] .jds-Select__control {
  border-color: var(--color-error--500) !important;
}

.Select_select__qM0Ez [class*="jds-Select__input-container"] {
  margin: 0 !important;
}

.Select_select__qM0Ez [class*="jds-Select__multi-value"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez [class*="jds-Select__placeholder"],
.Select_select__qM0Ez [class*="jds-Select__single-value"],
.Select_select__qM0Ez [class*="jds-Select__input"] {
  margin: 0 !important;
}

.Select_select__qM0Ez
  [class*="jds-Select__control--is-focused jds-Select__control--menu-is-open"]
  [class*="jds-Select__single-value"] {
  color: var(--color-grey--500) !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container"] {
  padding: 0 0 0 var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container--is-multi"] {
  max-height: 82px !important;
  overflow: auto !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container--is-multi jds-Select__value-container--has-value"] {
  padding: var(--spacer-100) 0 var(--spacer-100) var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-Select__multi-value__remove"] {
  background-color: inherit !important;
  color: inherit !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez .jds-Select__menu {
  box-shadow: var(--default-shadow) !important;
}

.Select_select__qM0Ez [class*="jds-Select__menu-list"] {
  max-height: 264px !important;
  padding: 0 !important;
  border-bottom: 1px solid var(--color-grey--100) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__menu {
  border-radius: var(--radius-400) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__indicators {
  height: var(--Select-innerHeight);
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
  margin-right: var(--spacer-200);
  padding: 0 var(--spacer-50) 0 var(--spacer-100);
  background-color: var(--color-grey--100);
  color: var(--color-black);
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__multi-value {
  border-radius: var(--radius-200) !important;
  background-color: var(--color-black) !important;
  color: var(--color-white) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__multi-value__label {
  color: var(--color-white) !important;
}

@media (min-width: 48rem) {
  [data-theme="jobteaser-uishift"]
    .Select_select__qM0Ez
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]
    > div[role="button"] {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
    padding: 0 var(--spacer-100) 0 var(--spacer-100);
  }

  [data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover {
    padding: 0 var(--spacer-50) 0 var(--spacer-150);
  }

  [data-theme="jobteaser-uishift"]
    .Select_select__qM0Ez
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover
    > div[role="button"] {
    display: inherit;
  }
}

}
@layer base {
  .LanguageModal_main__DNIhx {
  overflow-y: visible;
}

.LanguageModal_selectField__Dvx_x {
  /* Use z-index to display the select options list in front of the modal buttons */
  z-index: var(--z-above-ui-kit-buttons);
}

}
@layer base {
  .LegalModal_links__ruSJk {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 10px;
  /* Override BO londinium CSS */
  padding-left: 0;
  list-style-type: none;
  gap: var(--spacer-100);
  font-size: var(--smallBody);
}

}
@layer base {
  .MenuItem_link__HSrUN {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-radius: var(--radius-100);
  font-size: var(--normalBody);
  text-decoration: none;
  white-space: nowrap;
  gap: var(--spacer-100);
}

.MenuItem_link___unselected__R_QQs {
  color: var(--color-black);
}

.MenuItem_link___selected__wZrm8 {
  background-color: var(--color-black);
  color: var(--color-white) !important;
}

.MenuItem_link___collapsed__WpqKt {
  justify-content: center;
}

.MenuItem_link__HSrUN:hover {
  background-color: var(--color-grey--100);
  text-decoration: none;
}

.MenuItem_link___selected__wZrm8:hover {
  background-color: var(--color-grey--800);
}

.MenuItem_link__HSrUN:focus {
  box-sizing: border-box;
  padding: 0 calc(var(--spacer-100) - 1px);
  border: thin solid var(--color-black);
  outline: none;
}

.MenuItem_linkText__mGy1M {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuItem_linkText___hidden__Ctmip {
  display: none;
}

}
@layer base {
  /* stylelint-disable plugin/selector-bem-pattern */
/** @define Loading */

.Loading_sha-Loading__tmLTe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.Loading_sha-Loading__spinner--isOnWhiteBackground__pHKQ_ {
  stop-color: var(--mainColor);
}

}
@layer base {
  .Drawer_main__kqud4 {
  display: flex;
  position: fixed;
  z-index: var(--z-index-website-menu);
  box-sizing: border-box;
  flex-direction: column;
  width: var(--jt-bo-drawer-width);
  /* using svh instead of vh (when available) to avoid the drawer being too long on mobile devices */
  height: calc(100vh - var(--notification-banner-height));
  height: calc(100svh - var(--notification-banner-height));
  padding: 0 var(--spacer-100);
  background-color: var(--color-grey--025);
  box-shadow: 0 0 4px var(--color-grey--100);
}

.Drawer_main__collapsed__xsTvP {
  position: sticky;
  top: var(--notification-banner-height);
  width: var(--jt-bo-drawer-collapsed-width);
}

.Drawer_main__loading__u08fi {
  margin: 0;
}

.Drawer_backdrop__sokMw {
  position: fixed;
  /*
    Below the drawer but above the rest of the page.
    (It is below the drawer with the same z-index because it is before in order of appearance in the HTML.)
  */
  z-index: var(--z-index-website-menu);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--color-black);
}

.Drawer_spacer__Kke79 {
  width: var(--jt-bo-drawer-collapsed-width);
}

.Drawer_header__zltHJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-400);
  padding: var(--spacer-200) var(--spacer-150);
  transition: none;
  border-bottom: thin solid var(--color-grey--100);
  text-decoration: none;
  gap: var(--spacer-150);
}

.Drawer_headerLink__6VkMR,
.Drawer_headerLink__6VkMR:hover {
  display: flex;
  gap: var(--spacer-150);
  align-items: center;
  text-decoration: none;
}

.Drawer_header__collapsed__drQvf {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}

.Drawer_header__zltHJ:hover {
  text-decoration: none;
}

.Drawer_title__EYavJ {
  flex: 1;
}

.Drawer_collapse__lzPod {
  padding: 0 !important;
  border: none;
  stroke: var(--color-grey--500);
}

.Drawer_collapse__lzPod:hover::before {
  background: none;
}

.Drawer_logo__DLciR {
  /* ensure the link keeps the same size as the image */
  display: block;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-100);
  background-color: var(--color--grey-50);
}

.Drawer_menu__ta8GM {
  display: flex;
  flex-direction: column;
  min-height: 0; /* it allows the flex container to shrink below the height of its content if necessary */
  border-bottom: thin solid var(--color-grey--100);
}

.Drawer_menuItemsList__lyYP6 {
  margin-top: 0;
  /* Override BO londinium CSS */
  margin-bottom: 0;
  /* Override BO londinium CSS */
  padding-left: 0;
  /*
  The scrollable area is only on the menu items list and not on the whole menu,
  because the overflow-y auto or scroll is not compatible with the presence of dropdowns.
  It would create horizontal scrolling that we can't hide without making the dropdowns unusable.
  In CSS, if you are using visible for either overflow-x or overflow-y and something other than visible for the other,
  the visible value is interpreted as auto.
   */
  overflow-y: auto;
  list-style-type: none;
}

.Drawer_dropdowns__D9ciY {
  flex: 1;
}

.Drawer_helpCenter__cBTML {
  margin-top: var(--spacer-150);
  margin-bottom: var(--spacer-200);
}

@media (min-width: 48rem) {
  .Drawer_main__kqud4 {
    position: sticky;
    top: var(--notification-banner-height);
  }
}

}
